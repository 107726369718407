import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated React Native Developers | Outsource React Native App Development Services in India',
    metaDescription: 'Hire React Native MobileApp Developer & programmers to develop Native Android/iOS applications. Hire Now!',
    h1: 'Hire Dedicated React Native Developers',
    h1Subtext1: 'Build native android and ios apps using React Native. Both apps share the same code.',
    h1Subtext2: 'MatteCurve is the best React Native development company in India. We are a JavaScript company and have dedicated React Native developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire React Native developers within 7 days.',
    aboutSection: {
        title: 'About React Native',
        subTitle: 'A javascript library to build native android and ios apps.',
        paragraph1: "React Native is an open-source Mobile Application Framework that provides the facility to develop for both Android and iOS simultaneously. It was one of the rising technologies giving a great promise to save on development time but also carrying some risks.",
        paragraph2: `React Native also defines JavaScript interfaces for platform APIs, so that you can access platform features like the phone camera, or the user’s location in applications based on React Native. One form of code written in React Native is used by both platforms android and ios. We can easily use native views or components like Native Buttons, Native Activity Call, Dropdown, or anything. React Native builds are very fast, this speeds up our development.`,
        paragraph3: `To build mobile applications using React Native, A developer should be well versed with many concepts like React core concepts, Mobile development core concepts, generating mobile releases for android and ios, releasing mobile apps on google play store, and apple store, etc. A mobile developer should have good knowledge about platform specific permissions and APIs as they behave differently on each. Hire React Native developers from MatteCurve who are experienced and skilled in developing large scale mobile applications using React Native.`,
        keyPoints: [
            "React Native developers use primarily JavaScript when using the framework, the created app looks and run the same way as if it would be built with native iOS and Android technologies.",
            "React Native developers can work simultaneously on both the Android and iOS versions of the app, you don’t need to hire separate teams to handle these two platforms.",
            "React Native allows teams to share up to 95 percent of their code across Android and iOS. This can be a huge benefit in the era of agile development.",
            "React Native increases the flexibility and adaptability within a development team and makes it less complicated to create updates and upgrades to web applications.",
            "In react native you only have to put building blocks together using JavaScript, because it uses the same fundamental UI building blocks as regular android applications & iOS applications.",
            "Live reload provides you the service of reading & compiling a file that developers can modify for presenting in front of a simulator. It can also reload the app on its own from the initial stage."
        ],
    },
    statsSection: {
        paragraph: "React Native gaining a huge market day by day due to its user-friendly UI/UX designing features. In today’s digital era, 40% of the traffic is generated from mobile apps which means that smartphones hold more than 60% of all services from the internet. Here are some React Native app examples Airbnb, Artsy, Tesla, Walmart, Myntra. Not only does Facebook itself use the framework, but also Global Companies That Use React Native are  Bloomberg, Instagram, Skype, Uber Eats, and so on. Hire Dedicated React Native Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated React Native Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated React Native Developers?",
        "Hire dedicated React Native Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for React Native Development?",
        paragraph: "MatteCurve has been recognized as one of the leading web development companies in building high-performance React Native apps from India. MatteCurve offers you deep expertise in React Native app development, delivered by a team of highly-skilled React Native Developers with unmatched expertise in building secure apps.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
